import Image from 'next/legacy/image'
import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { c } from '@/utils/etc'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface RenderPDFProps {
  src: string
  onLoadError: (e: Error) => void
}

interface PDFImagePreviewProps {
  src: string
  containerClassName?: string
  renderFallback?: (p: { error?: Error }) => React.ReactElement
}

const RenderPDF: React.FCC<RenderPDFProps> = ({ src, onLoadError }) => {
  const [pageWidth, setPageWidth] = React.useState(0)
  const [numPages, setNumPages] = React.useState(0)
  const [success, setSuccess] = React.useState(false)

  const documentoRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const el = documentoRef.current
    if (!el) return
    setPageWidth(el.clientWidth)
  }, [documentoRef])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
    setSuccess(true)
  }

  return (
    <Document
      file={src}
      inputRef={documentoRef}
      className={c('absolute inset-0 opacity-0', success && 'opacity-100')}
      onPassword={() => {
        throw new Error('password-protected')
      }}
      onLoadError={onLoadError}
      onLoadSuccess={onDocumentLoadSuccess}
      options={{
        cMapPacked: true,
        cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
      }}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={pageWidth}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          className={c('flex flex-col')}
        />
      ))}
    </Document>
  )
}

const PDFImagePreview: React.FCC<PDFImagePreviewProps> = ({
  src,
  containerClassName,
  renderFallback = () => <></>,
}) => {
  const [loadError, setLoadError] = React.useState<Error>()

  const type = React.useMemo(() => {
    const ext = src.split('.').slice(-1)?.[0]?.toLowerCase()

    if (ext === 'pdf') return 'PDF'

    if (['jpg', 'jpeg', 'png'].includes(ext)) return 'IMAGE'
  }, [src])

  return (
    <div className={c('relative w-full h-full', containerClassName)}>
      {type === 'PDF' && !loadError && (
        <RenderPDF
          onLoadError={(e) =>
            e.message !== 'Worker was destroyed' && setLoadError(e)
          }
          src={src}
        />
      )}
      {type === 'IMAGE' && !loadError && (
        <Image src={src} layout="fill" objectFit="contain" alt="" />
      )}

      {loadError && renderFallback({ error: loadError })}
    </div>
  )
}

export default React.memo(PDFImagePreview, () => true)
