import Router from 'next/router'
import React from 'react'

export const useIsRouterTransitioning = () => {
  const [isTransitioning, setIsTransitioning] = React.useState(false)

  React.useEffect(() => {
    const start = (_url: string, opts: any) => {
      if (opts?.shallow) return
      setIsTransitioning(true)
    }
    const end = () => {
      setIsTransitioning(false)
    }
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)
    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])

  return isTransitioning
}
