import 'react-toastify/dist/ReactToastify.css'

import { useRouter } from 'next/router'
import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'

import { useIsRouterTransitioning } from '@/hooks/core/useIsRouterTransitioning'
import { useAuth } from '@/hooks/use-auth'
import { usePathname } from '@/navigation'
import { env } from '@/utils/envs'

import TermoColaboracaoCliente from '../cliente/TermoColaboracaoCliente'
import ContractSigner from './ContractSigner'
import CookiesNote from './cookies-note/cookies-note'
import FullscreenLoader from './fullscreen-loader'

const DynamicGlobals: React.FC = () => {
  const isRouterTransitioning = useIsRouterTransitioning()

  const { user, context } = useAuth()
  const pathname = usePathname() || ''

  const { query } = useRouter()
  //This variable checks if the form was called by the site
  const fromWebsite = query?.fromWebsite

  return (
    <>
      <FullscreenLoader open={isRouterTransitioning} />

      <ToastContainer
        className="px-2 pointer-events-auto"
        hideProgressBar={true}
        position="top-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        autoClose={45000}
        closeButton={<div className="mt-2 mr-1 text-dark-gray-500">x</div>}
      />

      {/* Apenas mostra os modais em área logada e para o ambiente BR */}
      {(pathname.startsWith('/area-') || pathname === '/projects') && user ? (
        <>
          {env.LANGUAGE === 'pt-br' && (
            <>
              <TermoColaboracaoCliente />
            </>
          )}
          {context !== 'BACKOFFICE' && user && <ContractSigner />}
        </>
      ) : null}

      {!fromWebsite && <CookiesNote />}
    </>
  )
}

export default DynamicGlobals
