import { createLocalizedPathnamesNavigation } from 'next-intl/navigation'
import type { ComponentPropsWithoutRef } from 'react'

import { config } from './i18n'

const { locales, pathnames } = config

const {
  Link,
  useRouter,
  usePathname,
  redirect,
  getPathname: _getPathname,
} = createLocalizedPathnamesNavigation({ locales, pathnames })

export const getPathname = (href: Href) =>
  _getPathname({ href: href as never, locale: config.defaultLocale })

export const isLinkActive = (href: Href, pathname: string) => {
  return pathname.split('?')[0]?.startsWith(getPathname(href))
}

export type Href<T extends keyof typeof pathnames = keyof typeof pathnames> =
  ComponentPropsWithoutRef<typeof Link<T>>['href']

export { Link, redirect, usePathname, useRouter }
