import { Transition } from '@headlessui/react'
import * as React from 'react'
import PulseLoader from 'react-spinners/PulseLoader'

import twcss from '../../../../tailwind.config'

export interface FullscreenLoaderProps {
  open: boolean
}

const FullscreenLoader: React.FCC<FullscreenLoaderProps> = ({ open }) => {
  React.useEffect(() => {
    document.body.style.setProperty('overflowY', 'hidden')
    return () => {
      document.body.style.removeProperty('overflowY')
    }
  }, [open])

  return (
    <Transition
      show={open}
      as={React.Fragment}
      enter="ease-out duration-300 delay-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 z-[60] flex items-center justify-center bg-white/50">
        <PulseLoader color={twcss.theme.colors.primary[300]} />
      </div>
    </Transition>
  )
}

export default FullscreenLoader
