import { useQuery } from '@tanstack/react-query'

import * as fetch from '@/utils/fetch'
import type { Proposta } from '@/utils/types/structs/proposta'

interface UsePropostaProps {
  queryKey: any[]
  cacheTime?: number
  enabled?: boolean
  limit?: number
  page?: number
  filter?: Record<string, any>
}

export const usePropostas = ({
  queryKey,
  cacheTime = 30000,
  enabled = true,
  limit = 10,
  page = 1,
  filter,
}: UsePropostaProps) => {
  const propostasQuery = useQuery(
    ['propostas', ...queryKey],
    () => {
      const params = new URLSearchParams()

      params.set('page', page.toString())
      params.set('limit', limit.toString())
      params.set('filter', JSON.stringify(filter))

      return fetch
        .portal<{ propostas: Proposta[] }>(`/proposta?${params}`)
        .then(({ data }) => data.propostas)
    },
    {
      cacheTime,
      enabled,
    }
  )

  return { isLoading: propostasQuery.isLoading, propostas: propostasQuery.data }
}
