import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Form } from 'react-final-form'
import * as yup from 'yup'

import Button from '@/components/core/Button'
import * as Input from '@/components/core/input'
import BaseModal from '@/components/core/Modals/BaseModal'
import { usePropostas } from '@/hooks/proposta/use-propostas'
import { useAuth } from '@/hooks/use-auth'
import { defaultToastError, toCliente, validateFormValues } from '@/utils/etc'
import * as fetch from '@/utils/fetch'
import { statesOptions } from '@/utils/types'

interface FormValues {
  aceito: boolean
}

const validate = validateFormValues(
  yup.object().shape({
    aceito: yup
      .bool()
      .typeError('É obrigatório aceitar o termo')
      .required('É obrigatório aceitar o termo'),
  })
)

const TermoColaboracaoCliente: React.FCC = () => {
  const { context, user } = useAuth()
  const queryClient = useQueryClient()

  const { propostas } = usePropostas({
    queryKey: ['cliente', user?.perfil?.id],
    filter: { clienteId: user?.perfil?.id, status: 'FORMALIZACAO' },
    enabled: Boolean(context === 'CLIENTE' && user?.perfil?.id),
  })

  const proposta = React.useMemo(
    () =>
      propostas?.find(
        ({ termoColaboracaoAceitoPeloClienteEm, isSimulacaoRapida, status }) =>
          !termoColaboracaoAceitoPeloClienteEm &&
          !isSimulacaoRapida &&
          status === 'FORMALIZACAO'
      ),
    [propostas]
  )

  const cliente = React.useMemo(() => toCliente(user), [user])

  const aceitaTermo = useMutation(
    async ({ aceito }: FormValues) => {
      if (!proposta || !propostas || !aceito) return

      const doit = (id: string) => {
        return fetch.portal(`/cliente/aceitar-termo/${id}`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            proposta: {
              termoColaboracaoAceitoPeloClienteEm: new Date().toISOString(),
            },
          }),
        })
      }

      return Promise.all(
        propostas
          ?.filter(
            ({ termoColaboracaoAceitoPeloClienteEm, isSimulacaoRapida }) =>
              !termoColaboracaoAceitoPeloClienteEm && !isSimulacaoRapida
          )
          .map(({ id }) => doit(id))
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'propostas',
          'cliente',
          user?.perfil?.id,
        ])
      },

      onError: defaultToastError,
    }
  )

  if (context !== 'CLIENTE') return null
  if (!proposta) return null
  if (!cliente) return null

  return (
    <BaseModal
      key={proposta.id}
      panelClassName="max-w-[80vw] max-h-[80vh] h-full"
      open
    >
      <Form onSubmit={aceitaTermo.mutateAsync as never} validate={validate}>
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-y-6 h-full"
          >
            <h1 className="text-dark-gray-500 font-bold text-title-sm">
              Contrato de Intermediação
            </h1>

            <div className="flex-1 overflow-y-auto">
              <p className="whitespace-pre-wrap">
                {`Pelo presente instrumento particular (“Contrato”), as partes (individualmente “Parte” e, em conjunto, “Partes”):

Contratante (“Contratante”):

Nome/Razão Social: ${
                  cliente?.empresa?.razaoSocial ||
                  cliente?.identidade?.nomeCompleto
                }
CPF/CNPJ: ${cliente?.empresa?.cnpj || cliente?.identidade?.cpf}
R.G. (se aplicável):
Endereço: ${
                  cliente?.empresa?.endereco?.logradouro ||
                  cliente?.identidade?.endereco?.logradouro
                }
Cidade: ${
                  cliente?.empresa?.endereco?.cidade ||
                  cliente?.identidade?.endereco?.cidade
                }
Estado: ${
                  statesOptions.find(
                    ({ value }) =>
                      value ===
                      (cliente?.empresa?.endereco?.estado ||
                        cliente?.identidade?.endereco?.estado)
                  )?.label
                }
CEP: ${cliente?.empresa?.endereco?.cep || cliente?.identidade?.endereco?.cep}
E-mail: ${
                  cliente?.credencial?.email ||
                  cliente?.empresa?.contato?.email ||
                  cliente?.identidade?.contato?.email
                }

GRIFO ENERGIA LTDA., sociedade empresária limitada com sede na Cidade de Ribeirão Preto, Estado de São Paulo, na Avenida Presidente Vargas, 1.265, sala 1108, 11º andar, Jardim São Luiz, CEP 14020-273, inscrita no CNPJ/ME sob nº 45.684.942/0001-74 (“Contratada”);

Considerando que:

(a)	o Contratante deseja implementar um projeto de energia renovável, nos termos da proposta de integrador/distribuidor a ser apresentada à Contratada e que passará a integrar o presente como Anexo I ao presente (“Projeto”), e que pretende realizar o respectivo pagamento mediante financiamento bancário; e

(b)	o Contratante tem interesse em contratar a Contratada para prestar ao Contratante serviços relativos à intermediação entre instituições financeiras e o Contratante para fins de contratação de financiamento bancário para a implementação do Projeto, observados os termos e condições estabelecidos neste Contrato,

Resolvem as Partes celebrar o presente Contrato, que será regido pelos seguintes termos e condições:

    1. Escopo e Prazo de Prestação dos Serviços

        1.1. Constitui objeto do presente Contrato a prestação, pela Contratada ao Contratante, de serviços de intermediação entre instituições financeiras e o Contratante para fins de obtenção de financiamento bancário para a implementação do Projeto (os “Serviços”).

        1.2. Para a prestação dos Serviços, será obrigatório o preenchimento de ficha cadastral pelo Contratante, bem como o fornecimento de documentos e informações relativos ao Contratante e ao Projeto.

            1.2.1. O Contratante será o único e exclusivo responsável pela veracidade das informações fornecidas à Contratada, sejam suas ou a respeito do Projeto.

    2. Remuneração e Despesas

        2.1. A Contratada receberá da instituição financeira contratada para financiar o Projeto uma remuneração fixa e/ou variável oriunda da intermediação do negócio, captação e/ou análise de crédito, dentre outras atividades decorrentes ou relacionadas ao financiamento (a “Remuneração”).

            2.1.1. Fica desde já certo e ajustado que o pagamento da Remuneração, nos termos da cláusula anterior, será realizado diretamente pela instituição financeira à Contratada, não sendo devido qualquer valor a esse título pelo Contratante.

            2.1.2. O Contratante desde reconhece e aceita que a Contratada poderá cobrar do Contratante uma taxa de análise prévia de viabilidade financeira do Projeto, conforme tabela disponibilizada pela Contratada ao Contratante, e que tal análise se restringirá à análise de projeções de retorno do investimento no Projeto vis-à-vis economias no consumo de energia da rede. Tendo em vista as variações no preço e consumo de energia, além de outros fatores técnicos como rendimento de equipamentos e sua manutenção, em nenhuma hipótese a Contratada será responsável caso o resultado de tal análise prévia de viabilidade financeira do Projeto não corresponda ao retorno efetivo do investimento realizado pelo Contratante.    

            2.1.3. As despesas necessárias à prestação dos Serviços, incluindo, mas a tanto não se limitando, custos com telefonemas, correios, serviços de courier e fotocópias, dentre outras, correrão por conta única e exclusiva do Contratante, que deverá reembolsar a Contratada no prazo de 5 (cinco) dias após a apresentação do respectivo relatório.

        2.2. Tendo em vista a natureza dos Serviços e da Remuneração, o Contratante não poderá, em hipótese alguma ou sob qualquer pretexto ou justificativa, sob pena de arcar com as respectivas perdas e danos (incluindo lucros cessantes), assumir negociação direta com qualquer instituição financeira que tenha sido consultada pela Contratada para fins de financiamento do Projeto, inclusive após eventual rescisão deste Contrato nos termos da Cláusula 6.3 abaixo.

        2.3. Caso o Contratante, por qualquer motivo, desista do financiamento após a assinatura do respectivo contrato com a instituição financeira, deverá reembolsar à Contratada e à instituição financeira pertinente todos os custos e despesas incorridos pela Contratada e a instituição financeira para a obtenção, análise e aprovação do financiamento.

    3. Obrigações Específicas das Partes

        3.1. Sem prejuízo das demais obrigações a ela atribuídas neste Contrato, a Contratada deverá:

    (a) manter sua regularidade jurídica e capacidade financeira;

    (b) manter o seu cadastro como correspondente bancário atualizado perante as instituições pertinentes;

    (c) planejar e prestar os Serviços em conformidade com (i) a legislação vigente aplicável, incluindo, sem limitação, a legislação tributária, trabalhista e previdenciária; e (ii) os preceitos éticos e profissionais inerentes às atividades a serem desenvolvidas;

    (d) alocar profissionais competentes e devidamente qualificados para a prestação dos Serviços;

    (e) não subcontratar qualquer das atividades relativas aos Serviços, a menos que autorizada pelo Contratante previamente e por escrito;

    (f) não assumir compromissos, contrair obrigações ou celebrar qualquer contrato em nome do Contratante, a menos que autorizada pelo Contratante previamente e por escrito;

    (g) informar prontamente o Contratante sobre qualquer fato, evento ou circunstância que possa adversamente afetar a prestação dos Serviços;

    (h) após o exaurimento do objeto do presente, devolver ao Contratante todos os documentos fornecidos para a prestação dos Serviços, se assim solicitada por escrito pelo Contratante; e

    (i) cumprir o disposto na legislação e regulamentação aplicável relativa à proteção de dados pessoais, nos termos da Cláusula 7 abaixo.

        3.2. Sem prejuízo das demais obrigações a ela atribuídas neste Contrato, o Contratante deverá:

    (a) cooperar com a Contratada para o bom andamento dos Serviços, fornecendo ou prestando à Contratada as informações, documentos e esclarecimentos que vierem a ser solicitados pela Contratada para a obtenção do financiamento;

    (b) caso a Contratada decida utilizá-los, a seu exclusivo critério, permitir acesso a vistoriadores próprios ou terceirizados indicados pela Contratada para a verificação de entrega de equipamentos financiados e serviços prestados por integradores e distribuidores, sendo civil e criminalmente responsável pela veracidade das informações que prestar a tal respeito; e

    (c) realizar o pagamento de eventuais reembolsos devidos à Contratada, na forma prevista neste Contrato. 

    4. Responsabilidades das Partes

        4.1. A Contratada não será de forma alguma responsável pela elaboração, revisão, viabilidade técnica e/ou financeira ou implementação do Projeto, bem como por formalização de garantias e outras providências a ele relativas.

        4.2. Os Serviços descritos no presente Contrato caracterizam-se como obrigação de meio, isto é, sem garantia do resultado a ser obtido.

    5. Confidencialidade

        5.1. As Partes deverão manter em absoluta confidencialidade todas as informações e documentos aos quais tiverem acesso em razão da prestação dos Serviços e que, pela sua natureza, não sejam ou devam ser públicos, bem como todas as informações e documentos que vierem a gerar ou produzir em decorrência da prestação dos Serviços, não podendo divulgá-los, transferi-los ou usá-los para finalidade diversa da prevista neste Contrato, salvo mediante autorização expressa e por escrito da outra Parte( “Informações Confidenciais”).

            5.1.1. As obrigações previstas nesta cláusula sobreviverão ao término deste Contrato, por um período de 2 (dois) anos.

        5.2. As obrigações de confidencialidade previstas nesta cláusula não serão aplicáveis a Informações Confidenciais que:

    (a) sejam ou venham a se tornar disponíveis ao público de outra forma que não por descumprimento deste Contrato;

    (b) sejam ou venham a se tornar de conhecimento da ou disponíveis à Parte receptora, em caráter não confidencial, por fonte (outra que não a Parte reveladora) que, de acordo com o conhecimento da Parte receptora, não se encontre, perante a Parte reveladora, sob a obrigação de não revelar tais Informações Confidenciais;

    (c) já sejam legalmente de conhecimento da Parte receptora na data de sua revelação sob este Contrato;

    (d) tenham sua revelação exigida segundo a legislação aplicável, incluindo normas de qualquer Bolsa de Valores pertinente, ou por ordem, decreto, regulamento ou regra governamental que obrigue a Parte receptora; sendo certo que, tão logo praticável e na medida em que permitido por lei, tal Parte receptora deverá notificar previamente e por escrito (e quando a notificação prévia e por escrito for impraticável mas legal, deverá notificar por escrito tão logo praticável a partir de então) à Parte reveladora que a revelação é exigida e consultar a Parte reveladora acerca da adoção de ação para resistir à exigência, e, em caso positivo, acerca de qual ação a ser adotada. Caso a Parte receptora tenha que adotar alguma ação para resistir à exigência por solicitação da Parte reveladora, os respectivos custos e despesas deverão ser arcados pela Parte reveladora.

    6. Prazo do Contrato e Rescisão

        6.1. Este Contrato entra em vigor nesta data e, a menos que antecipadamente rescindido de acordo com o aqui disposto, assim permanecerá até o exaurimento do seu objeto.

        6.2. Observado o disposto nas Cláusulas 2.2 e 2.3 acima, este Contrato poderá ser antecipadamente rescindido por qualquer das Partes, mediante o envio de notificação escrita à outra Parte, nas seguintes hipóteses:

    (a) em caso de insolvência, pedido de recuperação judicial ou extrajudicial, ou pedido ou decretação de falência da outra Parte; ou

    (b) na ocorrência de caso fortuito ou força maior que impossibilite a outra Parte de cumprir as obrigações a ela atribuídas neste Contrato por prazo superior a 15 (quinze) dias consecutivos ou 30 (trinta) dias não consecutivos; ou

    (c) em caso de descumprimento, total ou parcial, pela outra Parte, de qualquer obrigação a ela atribuída neste Contrato, desde que tal descumprimento não seja sanado no prazo de 15 (quinze) dias contados do recebimento de notificação por escrito enviada pela Parte inocente.

            6.2.1. Caso este Contrato seja rescindido nos termos da Cláusula 6.2 (c) acima, e observado o disposto nas Cláusulas 2.2 e 2.3 acima, a Parte inadimplente deverá prontamente indenizar a Parte inocente pelas perdas e danos (excluídos lucros cessantes ou quaisquer outros danos indiretos) efetivamente incorridos pela Parte inocente e diretamente decorrentes ou relacionados ao inadimplemento ou à rescisão deste Contrato.

        6.3. Sem prejuízo do disposto nas Cláusulas 2.2, 2.3 e 6.2 acima, este Contrato poderá ser rescindido, de pleno direito, por qualquer das Partes, mediante o envio de notificação por escrito à outra Parte com antecedência mínima de 30 (trinta) dias, nenhuma indenização sendo devida por uma Parte à outra em decorrência dessa rescisão.

    7. Proteção de Dados Pessoais

        7.1.  As Partes obrigam-se a atuar no presente Contrato em conformidade com a legislação vigente sobre Proteção de Dados Pessoais, com as determinações de órgãos reguladores/fiscalizadores sobre a matéria, bem como instruções estabelecidas no presente Contrato.

        7.2. Serão integralmente aplicáveis ao presente Contrato as disposições contidas na Política de Privacidade e Proteção de Dados da Contratada, conforme disponível no endereço www.eosfinanciamentosolar.com.br/politicadeprivacidade.

        7.3. O Contratante autoriza a coleta dos dados pessoais imprescindíveis à execução deste Contrato, tendo sido informado quanto ao tratamento de dados que será realizado pela Contratada, de acordo com as bases legais previstas nas hipóteses dos arts. 7º e/ou 11º da Lei 13.709/2018 (LGPD), às quais se submeterão os Serviços, assim como para propósitos legítimos, específicos, explícitos e informados ao titular.

        7.4. O Contratante autoriza o compartilhamento de seus dados, para os fins descritos neste Contrato, com terceiros legalmente legítimos relacionados à prestação dos Serviços, incluindo as instituições financeiras a serem consultadas para a obtenção do financiamento do Projeto.

    8. Disposições Gerais

        8.1. Este Contrato não cria qualquer relação de emprego entre as Partes ou entre o Contratante e qualquer pessoa envolvida pela Contratada em razão das atividades relacionadas à prestação dos Serviços, direta ou indiretamente, não se aceitando qualquer responsabilidade subsidiária ou solidária do Contratante em eventual reclamação trabalhista envolvendo a Contratada e tal pessoa. Este Contrato não cria qualquer sociedade, consórcio, associação, mandato, gestão de negócios ou outro vínculo jurídico semelhante entre as Partes.

        8.2. O Contratante não poderá, sem a prévia e expressa concordância da Contratada, ceder ou de qualquer outra forma transferir este Contrato ou qualquer dos direitos e/ou obrigações dele decorrentes.

        8.3. Este Contrato vincula e beneficia as Partes e seus herdeiros e sucessores, a qualquer título, os quais deverão cumprir todos os termos e condições aqui estabelecidos.

        8.4. Todas as notificações e autorizações que puderem ou tiverem que ser feitas ou dadas pelas Partes no âmbito deste Contrato serão válidas e eficazes somente se exteriorizadas através de correspondência (entregue pessoalmente, mediante protocolo, ou encaminhada pelo correio com aviso de recebimento) ou e-mail (com comprovação de recebimento) endereçados da seguinte forma (ou de outra forma que vier a ser posteriormente notificada por uma Parte à outra):

Se para o Contratante:
Endereço: aquele informado no preâmbulo do presente
E-mail: aquele informado no preâmbulo do presente

Se para a Contratada:
Endereço: Avenida Presidente Vargas, 1.265, sala 1108, 11º andar, Jardim São Luiz, Ribeirão Preto, SP, CEP 14020-273
E-mail:
At.:

            8.4.1. As notificações e autorizações serão consideradas recebidas (a) quando sob a forma de correspondência, na data indicada no protocolo ou aviso de recebimento, conforme o caso, e (b) quando sob a forma de e-mail, na data indicada no comprovante de recebimento, ressalvando-se, no entanto, que notificações e autorizações enviadas por uma Parte à outra em dia que não seja um dia útil na Cidade de Ribeirão Preto, Estado de São Paulo, ou após as 18:00 hs de um dia útil na Cidade de Ribeirão Preto, Estado de São Paulo, serão consideradas recebidas apenas no dia útil imediatamente subsequente.

        8.5. Qualquer omissão ou tolerância pelas Partes na exigência do correto e pontual cumprimento dos termos e condições, específicas ou genéricas, constantes deste Contrato, ou no exercício de qualquer prerrogativa dele decorrente, não constituirá qualquer tipo de renúncia, desistência ou novação, nem afetará o direito de qualquer das Partes de exercê-los a qualquer tempo.

        8.6. Qualquer alteração deste Contrato deverá ser feita por meio de aditivo escrito acordado pelas Partes, podendo a concordância do Contratante ser expressada digitalmente por meio de clique em caixa de verificação específica no site ou aplicativo da Contratada.

        8.7. Se qualquer disposição deste Contrato for considerada inválida, ilegal ou inexequível por qualquer razão, a validade, legalidade e exequibilidade das demais disposições contidas neste Contrato não serão, de maneira alguma, afetadas ou prejudicadas por esse fato, permanecendo em pleno vigor. As Partes deverão negociar, em boa-fé, a substituição de quaisquer disposições inválidas, ilegais ou inexequíveis por disposições válidas, legais e exequíveis, cujos efeitos deverão aproximar-se, tanto quanto possível, dos efeitos legais e econômicos almejados pelas disposições consideradas inválidas, ilegais ou inexequíveis.

        8.8. Este Contrato representa o acordo integral das Partes com relação às matérias aqui tratadas, prevalecendo sobre quaisquer documentos ou tratativas anteriores com o mesmo objeto.

        8.9. O presente Contrato de adesão está devidamente registrado no 2º Tabelião de Notas da cidade de Ribeirão Preto, Estado de São Paulo, e sua aceitação pelo Contratante poderá se dar legalmente por meio de assinatura ou certificação eletrônica ou clique em caixa específica de aceitação disponível no site da Contratada para tal fim (“opt-in”). 

	8.9.1. As partes reconhecem a forma de contratação por meios eletrônicos como válida e plenamente eficaz, ainda que estabelecida com assinatura eletrônica ou certificação fora dos padrões ICP-BRASIL, conforme o disposto no §2º do art. 10 da Medida Provisória nº 2.200/2001.

        8.10. As Partes elegem o foro da Comarca de Ribeirão Preto, Estado de São Paulo, como o único competente para dirimir qualquer dúvida ou controvérsia decorrente do presente Contrato, com renúncia expressa a qualquer outro, por mais privilegiado que seja.

        8.11. Este Contrato será regido e interpretado de acordo com as leis do Brasil.`.replace(
                  /undefined/g,
                  ''
                )}
              </p>
            </div>

            <div className="flex items-center justify-between space-x-6">
              <Input.Form.Checkbox
                name="aceito"
                label="Eu declaro que li e estou de acorco com os termos do Contrato de Intermediação"
                labelClassName="text-dark-gray-500"
              />
              <Button type="submit" className="button--primary">
                Confirmar
              </Button>
            </div>
          </form>
        )}
      </Form>
    </BaseModal>
  )
}

export default TermoColaboracaoCliente
