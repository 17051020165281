import { Transition } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import * as React from 'react'
import { HiOutlineX } from 'react-icons/hi'

import Button from '@/components/core/Button'

const CookiesNote: React.FCC = () => {
  const [showing, setShowing] = React.useState(false)
  const { pathname } = useRouter()
  const t = useTranslations()

  const setAccepted = React.useCallback(() => {
    document.cookie = 'cookies-consent=true;path=/;'
    setShowing(false)
  }, [])

  React.useEffect(() => {
    setShowing(!document.cookie.includes('cookies-consent=true'))
  }, [])

  if (
    [
      '/iframe/simulacao/[id]',
      '/export/proposta/[idProposta]',
      '/area-cliente/agreement-session',
      '/delete-account',
      '/help',
    ].includes(pathname)
  ) {
    return null
  }

  return (
    <Transition
      appear
      show={showing}
      as={React.Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed inset-x-4 bottom-4 z-30 ml-0 max-w-4xl items-center rounded-lg border border-light-gray-200 bg-white p-5 md:mx-auto"
        style={{
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        }}
      >
        <Button
          className="float-right mt-1 p-0"
          onClick={() => setShowing(false)}
        >
          <HiOutlineX />
        </Button>
        <div className="p-4">
          <span className=" text-xl font-bold text-primary-500">
            {t('common.COOKIE_POLICY_TITLE')}
          </span>
          <div className="flex flex-col items-center justify-between md:flex-row">
            <div>
              <p className="mt-5 max-w-md pb-2 text-sm text-light-gray-500 lg:text-base">
                {t('common.COOKIE_POLICY_DESCRIPTION')}
              </p>

              <Link
                href="/politicadeprivacidade"
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-primary-400 underline transition duration-300 hover:text-light-gray-400"
              >
                {t('common.COOKIE_POLICY')}
              </Link>
            </div>

            <Button
              onClick={setAccepted}
              className="button--primary md:mt-0h float-right mt-4"
            >
              {t('common.COOKIE_POLICY_BUTTON')}
            </Button>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default CookiesNote
