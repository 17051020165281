import type { Pathnames } from 'next-intl/routing'
import { getRequestConfig } from 'next-intl/server'

import { env } from './utils/envs'

export default getRequestConfig(async ({ locale }) => ({
  messages: (await import(`../messages/${locale}.json`)).default,
}))

const locales = ['en', 'pt-br'] as const
export type Locale = (typeof locales)[number]

function defineLegacyRoute<T extends string>(
  route: T
): Record<T, Record<Locale, string>> {
  return {
    [route]: {
      en: route,
      'pt-br': route,
    },
  } as unknown as Record<T, Record<Locale, string>>
}

const pathnames = {
  '/projects': {
    en: '/projects',
    'pt-br': '/propostas',
  },
  '/projects/new': {
    en: '/projects/new',
    'pt-br': '/propostas/nova',
  },
  '/projects/[projectId]': {
    en: '/projects/[projectId]',
    'pt-br': '/propostas/[projectId]',
  },
  '/projects/[projectId]/details': {
    en: '/projects/[projectId]/details',
    'pt-br': '/propostas/[projectId]/detalhes',
  },
  '/projects/[projectId]/documents': {
    en: '/projects/[projectId]/documents',
    'pt-br': '/propostas/[projectId]/documentos',
  },
  '/projects/[projectId]/milestones': {
    en: '/projects/[projectId]/milestones',
    'pt-br': '/propostas/[projectId]/milestones',
  },
  '/projects/[projectId]/applications': {
    en: '/projects/[projectId]/applications',
    'pt-br': '/propostas/[projectId]/fichas',
  },
  '/projects/[projectId]/credit': {
    en: '/projects/[projectId]/credit',
    'pt-br': '/propostas/[projectId]/credito',
  },
  '/projects/[projectId]/los': {
    en: '/projects/[projectId]/los',
    'pt-br': '/propostas/[projectId]/los',
  },
  '/projects/[projectId]/audit': {
    en: '/projects/[projectId]/audit',
    'pt-br': '/propostas/[projectId]/audit',
  },
  '/projects/[projectId]/payment': {
    en: '/projects/[projectId]/payment',
    'pt-br': '/propostas/[projectId]/payment',
  },
  '/projects/[projectId]/financier-data': {
    en: '/projects/[projectId]/financier-data',
    'pt-br': '/propostas/[projectId]/financier-data',
  },

  ...defineLegacyRoute('/area-backoffice/integradores/[id]/dados-pessoais'),
  ...defineLegacyRoute('/area-integrador/perfil/dados-pessoais'),
  ...defineLegacyRoute('/area-backoffice/integradores/[id]/dados-empresa'),
  ...defineLegacyRoute('/area-integrador/perfil/dados-da-empresa'),
  ...defineLegacyRoute('/area-backoffice/integradores/[id]/dados-pagamento'),
  ...defineLegacyRoute('/area-integrador/perfil/dados-de-recebimento'),
  ...defineLegacyRoute('/login'),
} satisfies Pathnames<typeof locales>
export type Routes = keyof typeof pathnames

export const config = {
  locales,
  defaultLocale: env.LANGUAGE as Locale,
  pathnames,
}
